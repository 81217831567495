<!-- 客服交班记录详情 -->
<template>
  <div class="shopDetail-container">
    <el-drawer
      class="shopDetail-drawer"
      title="客服交班详情"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    >
      <div
        v-loading="loading"
        element-loading-text="数据较多，拼命加载中..."
        class="drawer-container"
      >
        <div class="left-top">
          <div class="detail-title">
            <div class="tit-info">
              <div class="shopLogo-container">
                <img v-if="datailInfo.icon" :src="datailInfo.icon" alt="" />
                <i v-else class="el-icon-user common-iconImg-MR"></i>
              </div>
              <div class="shopName-custName">
                <div style="display: flex;">
                  <span>{{ datailInfo.adminName || "--" }}</span>
                  <span class="frname">{{ datailInfo.frequencyName||'--' }}</span>
                </div>
                <div style="display: flex;">
                  <span class="com">{{ datailInfo.companyName || "--" }}</span>
                  <span class="com">{{
                    datailInfo.departmentName
                      ? "|" + datailInfo.departmentName
                      : "--"
                  }}</span>
                </div>
              </div>
            </div>
            <div class="tit-text">
              <div>
                <span>上班接班时间：</span
                ><span class="b">{{ datailInfo.startTime || "--" }}</span>
              </div>
              <div>
                <span>交班时间：</span
                ><span class="b">{{ datailInfo.endTime || "--" }}</span>
              </div>
              <div>
                <span>上班时长：</span
                ><span class="b">{{ datailInfo.countDown || "--" }}</span>
              </div>
            </div>
          </div>
          <div class="line"></div>
        </div>
        <div class="tb">
          <div class="tb-tit">店铺服务数据</div>
          <div class="tb-l"></div>
          <div class="tb-table">
            <el-table
              class="common-table-haveborder common-table-bgGrey th"
              :data="datailInfo.detailList"
              border
            >
              <el-table-column prop="shopName" label="店铺名称">
              </el-table-column>
              <el-table-column prop="platformName" label="所属平台">
              </el-table-column>
              <el-table-column prop="subaccountName" label="子账号名称">
              </el-table-column>
              <el-table-column prop="receptionNum" label="系统接待量(参考)">
              </el-table-column>
              <el-table-column
                prop="actualReceptionNum"
                label="实际接待量(客服填写校对)"
              >
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="line"></div>
        <div class="jie">
          <div class="jie-tit">当前班次工作小结</div>
          <div class="jie-l"></div>
          <div class="greybgc">{{ datailInfo.remark }}</div>
          <div class="f">
            <div class="f-tit">附件</div>
            <div v-if="fileList && fileList.length" class="fileList-container">
              <div
                v-for="(item, index) in fileList"
                :key="index"
                class="fileItem"
              >
                <div class="fileItem-name">
                  <common-picture
                    :fileUrl="item.url"
                    :fileType="item.type"
                    :fileName="item.name"
                  ></common-picture>
                </div>
              </div>
            </div>
            <div
              v-if="!fileList || !fileList.length"
              class="fileList-container"
            >
              <div class="fileItem">
                暂无附件
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import { recordListDetail } from "../../../service/service";
import CommonPicture from "../../../components/common/commonPicture.vue";

export default {
  name: "",
  components: { CommonPicture },
  props: ["drawer"],
  data() {
    return {
      loading: false,
      changeShiftsId: "",
      params: {},
      datailInfo: {},
      fileName: [],
      fileUrl: [],
      fileList: [],
      itemType: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //方法集合
  methods: {
    async getDetail(id) {
      this.changeShiftsId = id;
      let params = { ...this.params };
      params.changeShiftsId = id;
      const { data } = await recordListDetail(params);
      this.datailInfo = data;
      if (data.fileName && data.fileUrl) {
        this.fileName = data.fileName.split(",");
        this.fileUrl = data.fileUrl.split(",");
      }
      this.fileList = [];
      this.fileUrl.forEach((item, i) => {
        let a = item.split(".")[item.split(".").length - 1];
        if (
          a.includes("jpeg") ||
          a.includes("jpg") ||
          a.includes("gif") ||
          a.includes("png")
        ) {
          this.itemType = "imgText";
        }
        this.fileList.push({
          name: this.fileName[i],
          url: item,
          type: this.itemType
        });
      });
    },
    handleClose(done) {
      this.fileName = [];
      this.fileUrl = [];
      this.fileList = [];
      this.datailInfo = {};
      this.$emit("handleClose", done);
    }
  }
};
</script>

<style lang="less" scoped>
.shopDetail-drawer {
  /deep/ .el-drawer__body {
    padding: 0;
  }
  .drawer-container {
    height: 100%;
    box-sizing: border-box;
    .fileNames {
      color: #1890ff;
      margin-top: 8px;
      word-break: break-all;
      cursor: pointer;
    }
    .drawer-left {
      padding: 32px 24px;
      text-align: left;
      width: 1150px;
      height: 100%;
      border-right: 1px solid #e8e8e8;
      box-sizing: border-box;
      overflow-x: hidden;
      .left-top {
        // display: flex;
        // justify-content: space-between;
        .demo-ruleForm {
          width: 920px;
        }
      }
      .tabsDetail {
        margin-top: 24px;
      }
      .upload-container {
        margin-top: 24px;
        .fileList-container {
          padding-left: 46px;
          border: 1px solid #ebeef5;
          margin-top: 12px;
          border-radius: 4px;
          .fileItem {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            border-bottom: 1px solid #ebeef5;
            height: 74px;
            line-height: 74px;
            align-items: center;
            .fileItem-btn-Container {
              display: flex;
              .fileItemBtn {
                margin-right: 16px;
                color: #1890ff;
                cursor: pointer;
              }
            }
          }
        }
        .upload-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #999999;
          .tip {
            font-size: 12px;
          }
          .upload-title {
            font-size: 18px;
            color: #101010;
          }
          .uploadBtn-container {
            width: 100px;
            height: 32px;
            line-height: 32px;
            border: 1px solid #1890ff;
            color: #1890ff;
            font-size: 14px;
            background: #fff;
            text-align: center;
            border-radius: 4px;
            display: inline-block;
            margin-left: 16px;
            position: relative;
            .uploadBtn-input {
              width: 100px;
              height: 32px;
              cursor: pointer;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              font-size: 0;
            }
          }
        }
      }
      .cancel {
        cursor: pointer;
      }
      /deep/ .el-input__inner,
      .el-input-group__append {
        height: 32px;
        line-height: 32px;
      }
      /deep/ .el-input-group__append {
        height: 30px;
        line-height: 30px;
        padding: 0 8px;
      }
    }
    .drawer-right {
      width: 500px;
      height: 100%;
      overflow-y: auto;
      box-sizing: border-box;
      /deep/.el-tabs__content {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-left: 16px;
      }
      /deep/.el-tab-pane {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .detail-title {
      padding: 0 0 16px 12px;
      .tit-info {
        display: flex;
        align-items: center;
        margin-top: 32px;
        margin-left: 12px;
        .shopLogo-container {
          width: 64px;
          height: 64px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
          }
          .common-iconImg-MR {
            width: 64px;
            height: 64px;
            font-size: 50px;
            line-height: 64px;
          }
        }
        .shopName-custName {
          // display: inline-block;
          display: flex;
          flex-direction: column;
          margin: 0 16px;
          span {
            display: block;
            &:nth-of-type(1) {
              color: #333;
              font-size: 18px;
            }
            &:nth-of-type(2) {
              color: #999;
              font-size: 14px;
            }
          }
          .frname {
            display: inline-block;
            margin-left: 16px;
            padding: 4px 12px;
            background-color: #52c419;
            color: #fff !important;
            border-radius: 100px;
          }
          .com {
            font-size: 14px !important;
            color: #999 !important;
          }
        }
        .orderState {
          padding: 2px 8px;
          border-radius: 4px;
          border: 1px solid #f98787;
          color: #f98787;
          margin-left: 16px;
        }
      }
      .tit-text {
        display: flex;
        margin: 39px 0 0px 12px;
        div {
          width: 390px;
        }
        .b {
          color: #333;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 330px;
        padding: 6px 12px;
        margin-bottom: 20px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 95%
            center #f2f9ff;
        }
      }
    }
    .detail-content {
      .note-input-container {
        display: inline-block;
        width: 480px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .edit-input-container {
        display: inline-block;
        width: 280px;
        height: 32px;
        padding: 0 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
        /deep/ .el-form-item__label {
          line-height: 32px;
        }
      }
      .editInputContainer {
        width: 280px;
        white-space: nowrap;
      }
      .edit-input {
        width: 180px;
        height: 32px;
        line-height: 32px;
        vertical-align: top;
      }
      .noteHoverEdit {
        display: inline-block;
        // width: 100%;
        padding: 9px 48px 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;
        display: flex;
        .noteName {
          white-space: nowrap;
        }
        .editColor {
          vertical-align: top;
          display: inline-block;
          color: #1890ff;
          white-space: pre-wrap;
        }
        cursor: pointer;
        &:hover {
          background: url("../../../assets/images/edit_icon.svg") no-repeat 99%
            center #f2f9ff;
        }
      }
      .hoverEdit {
        display: inline-block;
        width: 280px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        margin-bottom: 22px;
        border-radius: 4px;

        &:last-child {
          width: 360px;
        }
      }
      .DisableEdit {
        display: inline-block;
        width: 200px;
        height: 21px;
        padding: 9px 12px;
        font-size: 12px;
        color: #999;
        border-radius: 4px;
      }
    }
    .about-content {
      padding-bottom: 18px;
      .fileNames {
        height: 20px;
        color: #1890ff;
        font-size: 12px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .con-rec {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .addFileBtn {
          height: 20px;
          color: #1890ff;
          font-size: 14px;
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 8px;
          .addFileInput {
            position: absolute;
            left: 0;
            top: 0;
            width: 120px;
            height: 20px;
            opacity: 0;
            cursor: pointer;
            font-size: 0;
          }
        }
      }
    }
  }
  /deep/ .el-drawer__open .el-drawer.rtl {
    width: 1656px !important;
  }
  .common-screen-container {
    margin: 0;
    margin-bottom: 8px;
    margin-top: 24px;
    .set {
      height: 32px;
      line-height: 8px;
      margin-left: 28px;
    }
  }
  .tableshow {
    text-align: right;
    margin-bottom: 32px;
    .square {
      display: inline-block;
      width: 10px;
      height: 10px;
      &:not(:first-child) {
        margin-left: 40px;
      }
    }
    .no {
      background-color: #757775;
    }
    .y {
      background-color: #56c51e;
    }
    .n {
      background-color: #f5232d;
    }
  }
  .systemServiceData-content {
    margin: 48px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    .systemServiceData-table {
      display: table;
      width: 100%;
      border-collapse: collapse;
      td,
      th {
        text-align: center;
        border: 1px solid #c9c9c9;
        color: rgba(0, 0, 0, 0.65);
        font-size: 14px;
      }
      th {
        background: #f5f5f5;
        height: 43px;
        line-height: 43px;
        font-weight: 600;
      }
      td {
        height: 58px;
        line-height: 58px;
      }
    }
  }
}
.line {
  margin: 32px 0;
  width: 100%;
  height: 1px;
  background-color: #eaeaeb;
}
.tb {
  margin: 0 32px 0 24px;
  .tb-tit {
    margin-top: 32px;
    font-size: 18px;
    color: #333;
  }
  .tb-l {
    width: 100%;
    border-bottom: #eaeaeb dashed 1px;
    margin-bottom: 24px;
  }
}
.jie {
  margin: 0 32px 0 24px;
  .jie-tit {
    margin-top: 32px;
    font-size: 18px;
    color: #333;
  }
  .jie-l {
    width: 100%;
    border-bottom: #eaeaeb dashed 1px;
    margin-bottom: 24px;
  }
  .greybgc {
    width: 100%;
    background-color: #f2f4f5;
    padding: 24px 32px;
  }
  .f-tit {
    margin-top: 32px;
    font-size: 18px;
    color: #333;
  }
  .fileList-container {
    padding-left: 46px;
  }
}
</style>
